import React from "react";
import "./Footer.css";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faPhone,
  faPhoneAlt,
  faMapMarkerAlt,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTiktok,
  faFacebook,
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <h4>QuePlan.cl</h4>
            <ul className="footer-list">
              <li>
                <FontAwesomeIcon icon={faUserCircle} /> Customer Portal Access
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} /> Contact
              </li>
              <li>
                <FontAwesomeIcon icon={faPhoneAlt} /> +562 2712 7123
              </li>
              <li>
                <FontAwesomeIcon icon={faPhoneAlt} /> +562 2712 7123
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Monsignor Sotero Sanz
                #100, Of 801
              </li>
              <li>
                <FontAwesomeIcon icon={faNewspaper} /> Press Contact
              </li>
            </ul>
            <div className="footer-icons">
              <FontAwesomeIcon icon={faTiktok} />
              <FontAwesomeIcon icon={faFacebook} />
              <FontAwesomeIcon icon={faLinkedin} />
              <FontAwesomeIcon icon={faInstagram} />
              <FontAwesomeIcon icon={faTwitter} />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <h4>Insurance</h4>
            <ul className="footer-list">
              <li>Compare Health Insurance</li>
              <li>Complementary Insurance</li>
              <li>Catastrophic Insurance</li>
              <li>School/University Insurance</li>
              <li>Business Insurance</li>
              <li>Life Insurance</li>
              <li>Travel Insurance</li>
              <li>Chilean Insurance Companies</li>
              <li>Clinics in Chile</li>
              <li>Product Map</li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <h4>Isapres</h4>
            <ul className="footer-list">
              <li>Compare Isapres Online</li>
              <li>Your Best Plan (beta)</li>
              <li>Isapre Plans Finder</li>
              <li>Chilean Isapres</li>
              <li>Isapres Product Map</li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <h4>More from QuePlan.cl</h4>
            <ul className="footer-list">
              <li>Partners and Alliances</li>
              <li>About QuePlan.cl</li>
              <li>Do your internship at QPTech</li>
              <li>QP Analytics</li>
              <li>QP Blog</li>
              <li>QP Budgets</li>
              <li>Terms and Conditions</li>
              <li>Queplan.cl Referrals</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
