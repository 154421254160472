import React, { useContext, useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./Sidebar.css";
import { FilterContext } from "../../Contexts/FilterContext";

const Sidebar = ({ cardCount, onFiltersChange }) => {
  const { filters, setFilters } = useContext(FilterContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isapresData = {
    Banmedica: 76,
    Colmena: 141,
    Consalud: 76,
    CruzBlanca: 0,
    Esencial: 4,
    "Nueva Masvida": 107,
    Vidatres: 41,
  };

  const coverageOptions = [60, 70, 80, 90];

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      freeChoice:
        prevFilters.freeChoice !== undefined ? prevFilters.freeChoice : true,
      preferred:
        prevFilters.preferred !== undefined ? prevFilters.preferred : true,
      closed: prevFilters.closed !== undefined ? prevFilters.closed : true,
      isapres: prevFilters.isapres || Object.keys(isapresData),
      priceRange: prevFilters.priceRange || [0, 500],
    }));
  }, [setFilters]);

  useEffect(() => {
    const queryParams = new URLSearchParams();

    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (key === "isapres") {
        if (
          value.length === 0 ||
          value.length === Object.keys(isapresData).length
        ) {
          queryParams.set(key, "[]");
        } else {
          queryParams.set(key, JSON.stringify(value));
        }
      } else if (Array.isArray(value)) {
        queryParams.set(key, value.join(","));
      } else {
        queryParams.set(key, value);
      }
    });

    onFiltersChange(queryParams.toString());
  }, [filters, onFiltersChange]);

  const handleIsapresCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setFilters((prevFilters) => {
      let updatedIsapres = [...prevFilters.isapres];

      if (checked) {
        if (!updatedIsapres.includes(name)) {
          updatedIsapres.push(name);
        }
      } else {
        updatedIsapres = updatedIsapres.filter((isapre) => isapre !== name);
      }

      if (updatedIsapres.length === 0) {
        updatedIsapres = Object.keys(isapresData);
      }

      return {
        ...prevFilters,
        isapres: updatedIsapres,
      };
    });
  };

  const handleFilterCheckboxChange = (event, filterKey) => {
    const { checked } = event.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: checked,
    }));
  };

  const handleSliderChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      priceRange: value,
    }));
  };

  const handleCoverageChange = (coverageType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [coverageType]: value,
    }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isSidebarOpen ? "Close Filters" : "Open Filters"}
      </button>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="close-sidebar" onClick={toggleSidebar}>
          ✖
        </div>
        <div className="results">
          <h2>{cardCount} Results</h2>
          <hr />
        </div>
        <div className="filter-card">
          <div className="filter-header">
            <h3>Price</h3>
            <i className="info-icon">i</i>
          </div>
          <div className="slider-container">
            <div className="price-labels">
              <span>${filters.priceRange[0]}</span>
              <span>${filters.priceRange[1]}</span>
            </div>
            <Slider
              range
              min={0}
              max={500}
              defaultValue={filters.priceRange}
              onChange={handleSliderChange}
              trackStyle={[{ backgroundColor: "#00aaff" }]}
              handleStyle={[
                { borderColor: "#00aaff", backgroundColor: "#fff" },
                { borderColor: "#00aaff", backgroundColor: "#fff" },
              ]}
            />
          </div>
        </div>
        <div className="filter-card">
          <div className="filter-header">
            <h3>Clinics and Hospitals</h3>
            <i className="info-icon">i</i>
          </div>
          <div className="clinic-filters">
            <div className="checkbox">
              <input
                type="checkbox"
                name="freeChoice"
                checked={filters.freeChoice || false}
                onChange={(e) => handleFilterCheckboxChange(e, "freeChoice")}
              />
              <label>Free choice</label>
              <span>0</span>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                name="preferred"
                checked={filters.preferred || false}
                onChange={(e) => handleFilterCheckboxChange(e, "preferred")}
              />
              <label>Preferred</label>
              <span>0</span>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                name="closed"
                checked={filters.closed || false}
                onChange={(e) => handleFilterCheckboxChange(e, "closed")}
              />
              <label>Closed</label>
              <span>0</span>
            </div>
          </div>
        </div>
        <div className="filter-card">
          <div className="filter-header">
            <h3>Coverages</h3>
            <i className="info-icon" style={{ float: "right" }}>
              i
            </i>
          </div>
          <div className="coverage-section">
            <h4>Hospitable</h4>
            <div className="coverage-options">
              {coverageOptions.map((coverage) => (
                <button
                  key={coverage}
                  className={
                    filters.hospitableCoverage === coverage ? "active" : ""
                  }
                  onClick={() =>
                    handleCoverageChange("hospitableCoverage", coverage)
                  }>
                  {coverage}%
                </button>
              ))}
            </div>
          </div>
          <div className="coverage-section">
            <h4>Ambulatory</h4>
            <div className="coverage-options">
              {coverageOptions.map((coverage) => (
                <button
                  key={coverage}
                  className={
                    filters.ambulatoryCoverage === coverage ? "active" : ""
                  }
                  onClick={() =>
                    handleCoverageChange("ambulatoryCoverage", coverage)
                  }>
                  {coverage}%
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="filter-card">
          <div className="filter-header">
            <h3>Isapres</h3>
            <i className="info-icon">i</i>
          </div>
          <div className="isapres-filters">
            {Object.keys(isapresData).map((key) => (
              <div className="checkbox" key={key}>
                <input
                  type="checkbox"
                  name={key}
                  checked={filters.isapres?.includes(key) || false}
                  onChange={handleIsapresCheckboxChange}
                />
                <label>{key}</label>
                <span>{isapresData[key]}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-card">
          <div className="filter-header">
            <h3>Comprehensive Benefits</h3>
            <i className="info-icon">i</i>
          </div>
          <p>
            As of August 2024, generating "surpluses" is no longer allowed. That
            is, a price must be agreed with the Isapre for at least 7% of your
            health contribution.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
