import React, { useState, useContext } from "react";
import { FilterContext } from "../../Contexts/FilterContext";
import "./TopFilters.css";

const TopFilters = () => {
  const { filters, setFilters } = useContext(FilterContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSortChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: e.target.value,
    }));
  };

  const handleCurrencyChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      currency: e.target.value,
    }));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="top-filters-container">
      <div className="top-filters">
        <button className="score-button" onClick={handleOpenModal}>
          What is the Score?
        </button>
        <div className="filter-options">
          <span>Sort by</span>
          <select value={filters.sortBy || ""} onChange={handleSortChange}>
            <option value="best_score">Best Score</option>
            <option value="lowest_price">Lowest Price</option>
          </select>
          <span>Currency</span>
          <select
            value={filters.currency || ""}
            onChange={handleCurrencyChange}>
            <option value="pesos">Pesos</option>
            <option value="usd">USD</option>
          </select>
        </div>
      </div>

      {isModalOpen && (
        <div className="score-modal-overlay" onClick={handleCloseModal}>
          <div className="score-modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
            <div className="modal-content" style={{ textAlign: "left" }}>
              <h2>What is the Score?</h2>
              <p>
                The plans are evaluated between 0 and 10 points. 10 points
                represent a perfect plan in which you would not have to pay for
                the treatment, since the coverage percentages would be 100% and
                the limits cover the most expensive providers for all services
                in free choice mode. But in addition to this, we give a scale
                relative to your search, this means that the maximum score you
                will see will be that of the best plan for your search.
                <br /> We recommend a score of 6.5 or higher to have good
                coverage at the 5 most expensive clinics in Chile. A score of
                2.5 can represent good coverage as long as you receive care from
                providers with prices in line with the plan's bonus.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopFilters;
