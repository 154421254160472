// WhyHireSection.js
import React from "react";
import "./WhyHireSection.css";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../../Assets/img1.webp";

const WhyHireSection = () => {
  return (
    <Container className="why-hire-section">
      <Row className="align-items-center">
        <Col xs={12} md={6} className="why-hire-image-container">
          <img src={img1} alt="Why Hire" className="why-hire-image" />
        </Col>
        <Col xs={12} md={6}>
          <h2 className="why-hire-title">Why hire at QuePlan.cl?</h2>
          <ul className="why-hire-list">
            <li className="why-hire-list-item">
              <span className="why-hire-check-icon">✔</span> You find products
              that are not anywhere else.
            </li>
            <li className="why-hire-list-item">
              <span className="why-hire-check-icon">✔</span> We are always with
              you, we respond quickly and clearly.
            </li>
            <li className="why-hire-list-item">
              <span className="why-hire-check-icon">✔</span>{" "}
              <span className="why-hire-strong">Best Price Guarantee:</span> We
              offer prices equal to or better than those you will find directly
              from the company.
            </li>
            <li className="why-hire-list-item">
              <span className="why-hire-check-icon">✔</span> The flow is{" "}
              <span className="why-hire-strong">100% online</span>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyHireSection;
