import React, { useState, useEffect, useContext } from "react";
import { FilterContext } from "../../Contexts/FilterContext";
import "./Filter.css";
import header from "../../Assets/headerbg.svg";

const regions = [
  { name: "Metropolitana", value: "RM" },
  { name: "Arica y Parinacota", value: "Norte" },
  { name: "Tarapacá", value: "Norte" },
  { name: "Antofagasta", value: "Norte" },
  { name: "Atacama", value: "Norte" },
  { name: "Coquimbo", value: "Norte" },
  { name: "Valparaíso", value: "Quinta" },
  { name: "O'Higgins", value: "Centro" },
  { name: "Maule", value: "Centro" },
  { name: "Ñuble", value: "Octava" },
  { name: "Biobío", value: "Octava" },
  { name: "Araucanía", value: "Sur" },
  { name: "Los Ríos", value: "Sur" },
  { name: "Los Lagos", value: "Sur" },
  { name: "Aysén", value: "Sur" },
  { name: "Magallanes", value: "Sur" },
];

const Filter = () => {
  const { filters, setFilters } = useContext(FilterContext);
  const [region, setRegion] = useState(filters.region || "");
  const [income, setIncome] = useState(filters.income || "");
  const [age, setAge] = useState(filters.age || "");
  const [sex, setSex] = useState(filters.sex || "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insuredMembers, setInsuredMembers] = useState([{ sex: "", age: "" }]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showFilters, setShowFilters] = useState(!isMobile);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      loads: prevFilters.loads || [], // Initialize loads as an empty array if not already set
    }));
  }, [setFilters]);

  useEffect(() => {
    const loadArray = insuredMembers
      .map((member) => member.age)
      .filter((age) => age !== "");

    setFilters((prevFilters) => ({
      ...prevFilters,
      loads: loadArray.length > 0 ? loadArray : [], // Ensure loads is an array, even if empty
    }));
  }, [insuredMembers, setFilters]);

  const updateQueryParams = () => {
    const params = new URLSearchParams();

    // Update other filters as query parameters
    if (region) params.set("region", region);
    if (income) params.set("income", income);
    if (age) params.set("age", age);
    if (sex) params.set("sex", sex);

    // Handle loads array
    if (filters.loads && filters.loads.length > 0) {
      filters.loads.forEach((value) => {
        params.append("loads[]", value); // Append each value with array syntax
      });
    } else {
      params.append("loads[]", ""); // Ensure the loads parameter is sent as an empty array
    }

    console.log("Query Params being set:", params.toString());

    // Use replaceState to update the URL without reloading the page
    window.history.replaceState(null, null, `?${params.toString()}`);

    // For sending the network request with these query parameters
    return params;
  };

  const sendRequest = () => {
    const params = updateQueryParams();

    fetch(`/api/filter?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => console.log("Response Data:", data))
      .catch((error) => console.error("Error:", error));
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddInsured = () => {
    setInsuredMembers([...insuredMembers, { sex: "", age: "" }]);
  };

  const handleRemoveInsured = (index) => {
    const list = [...insuredMembers];
    list.splice(index, 1);
    setInsuredMembers(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...insuredMembers];
    list[index][name] = value;
    setInsuredMembers(list);
  };

  const calculateContributorsAndLoad = () => {
    const ageArray = insuredMembers
      .map((member) => member.age)
      .filter((age) => age !== "");

    setFilters((prevFilters) => ({
      ...prevFilters,
      loads: ageArray.length > 0 ? ageArray : [],
    }));

    if (isModalOpen) {
      toggleModal();
    }

    sendRequest();  
  };

  return (
    <section className="the-filter container-fluid">
      <div className="header-box container-fluid animate__animated animate__backInDown">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="header-box">
              <h2 className="sky-blue">
                <b>Isapre plans</b>
              </h2>
              <p>Compare and Quote all Isapre Plans Online</p>
            </div>
          </div>
          <div className="col-lg-6 image-fade-container">
            <img src={header} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <center>
        <div className="new-filter container-fluid animate__animated animate__backInUp">
          {isMobile && (
            <button
              className="btn show-filters-btn"
              onClick={() => setShowFilters(!showFilters)}>
              {showFilters ? "Hide Filters" : "Show Filters"}
            </button>
          )}
          {showFilters && (
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-2 col-md-12">
                <div className="input-filter">
                  <div className="dropdown">
                    <button
                      className="btn-dropdown dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {region ? region : "Select Region"}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton">
                      {regions.map((regionItem, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              setRegion(regionItem.name);
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                region: regionItem.value,
                              }));
                            }}>
                            {regionItem.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="input-filter">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Net Monthly Income"
                    value={income}
                    onChange={(e) => {
                      setIncome(e.target.value);
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        income: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-1 col-md-4">
                <div className="input-filter">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Age"
                    value={age}
                    onChange={(e) => {
                      setAge(e.target.value);
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        age: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="input-filter">
                  <select
                    className="form-select"
                    value={sex}
                    onChange={(e) => {
                      setSex(e.target.value);
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        sex: e.target.value,
                      }));
                    }}>
                    <option value="" disabled>
                      Sex
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 d-md-flex justify-content-end">
                <button className="btn add-btn" onClick={toggleModal}>
                  <i className="fas fa-user-plus"></i> Modify insured
                </button>
              </div>
              <div className="col-lg-2 col-md-6 d-md-flex justify-content-end">
                <button
                  className="btn calc-btn"
                  onClick={calculateContributorsAndLoad}>
                  Calculate Price
                </button>
              </div>
            </div>
          )}
        </div>
      </center>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close" onClick={toggleModal}>
              &times;
            </button>
            <h4 className="modal-title">Add insured</h4>
            <div className="insured-section">
              {insuredMembers.map((member, index) => (
                <div key={index} className="insured-member">
                  <div className="input-group">
                    <label>Sex</label>
                    <select
                      name="sex"
                      value={member.sex}
                      onChange={(e) => handleInputChange(e, index)}>
                      <option value="" disabled>
                        Select Sex
                      </option>
                      <option value="Male">Man</option>
                      <option value="Female">Woman</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label>Age</label>
                    <input
                      type="number"
                      name="age"
                      value={member.age}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                  {insuredMembers.length > 1 && (
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveInsured(index)}>
                      &minus;
                    </button>
                  )}
                </div>
              ))}
              <button className="add-insured-btn" onClick={handleAddInsured}>
                + Add insured
              </button>
            </div>
            <button
              className="btn modal-calc-btn"
              onClick={calculateContributorsAndLoad}>
              Add and Calculate Contributor and Load
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Filter;
