import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import "./ContentCard.css";
import tableData from "./table_data.json";

const formatText = (text) => {
  if (!text) return "";
  const items = text.split("|").map((item) => item.trim());
  if (items.length > 1) {
    return `${items[0].split(" ")[0]} | ${items[1].split(" ")[0]} + other`;
  }
  return items[0].split(" ")[0];
};

const formatPrice = (price) => {
  return price % 1 === 0 ? price : price.toFixed(2);
};

const ContentCard = ({ card, selectedCurrency, ufToClpRate }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("general");
  const [detailData, setDetailData] = useState(null);
  const [uniqueId, setUniqueId] = useState("");

  const storedPrice = parseFloat(card.price);
  const [calculatedCLPPrice, setCalculatedCLPPrice] = useState(0);

  useEffect(() => {
    if (card) {
      calculateFinalPrice();
    }
  }, [card]);

  const calculateFinalPrice = () => {
    const finalCLPPrice = storedPrice * ufToClpRate;
    setCalculatedCLPPrice(finalCLPPrice);
  };

  const openModal = (tab) => {
    if (card && card.unique_id) {
      const data = tableData[card.unique_id];
      setUniqueId(card.unique_id);
      if (data) {
        setDetailData(data);
      } else {
        setDetailData("No data available for this unique_id");
      }
      setActiveTab(tab);
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setDetailData(null);
    setUniqueId("");
  };

  const logoUrl = `https://admin.zeenatonlinestore.com/${card.logo}`;

  return (
    <div className="content-card">
      {card ? (
        <>
          <div className="card-left">
            <div className="top-section">
              <div className="left-items">
                <div className="tag">{card.category}</div>
                <img className="logo" src={logoUrl} alt="Logo" />
              </div>
              <div className="right-items">
                <div className="compare">
                  <input type="checkbox" className="compare-checkbox" />
                  <span>Comparar</span>
                </div>
                <div className="score-section">
                  <div
                    className="score-value"
                    style={{ backgroundColor: "red", color: "white" }}>
                    Score: 7.3 out of 7.3
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <h2 id="h2">{card.title}</h2>
              <div className="details py-3">
                <div className="detail">
                  <i className="icon icon-provider">🏥</i>
                  <div>
                    <span className="detail-heading">Prestadores</span>
                    <span>{formatText(card.providers)}</span>
                  </div>
                </div>
                <div className="detail">
                  <i className="icon icon-hospital">🏥</i>
                  <div>
                    <span className="detail-heading">Hospitalaria</span>
                    <span>{formatText(card.hospitalaria)}</span>
                  </div>
                </div>
                <div className="detail">
                  <i className="icon icon-ambulatory">👨‍⚕</i>
                  <div>
                    <span className="detail-heading">Ambulatoria</span>
                    <span>{formatText(card.ambulatoria)}</span>
                  </div>
                </div>
                <div className="detail">
                  <i className="icon icon-cap">📈</i>
                  <div>
                    <span className="detail-heading">Tope Anual</span>
                    <span>{card.annual_Cap}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-right">
            <div className="price-section">
              <span>Desde</span>
              {selectedCurrency === "usd" ? (
                <h2>{formatPrice(storedPrice)} UF</h2>
              ) : (
                <h2>{formatPrice(calculatedCLPPrice)} CLP</h2>
              )}
            </div>
            <button
              className="request-button"
              onClick={() => openModal("request")}>
              Solicitar
            </button>
            <button
              className="detail-button"
              onClick={() => openModal("general")}>
              Ver Detalle
            </button>
          </div>
          {modalIsOpen && (
            <Modal
              isOpen={modalIsOpen}
              onClose={closeModal}
              detailData={detailData}
              activeTab={activeTab}
              uniqueId={uniqueId}
              tableData={tableData}
            />
          )}
        </>
      ) : (
        <div>No card data available.</div>
      )}
    </div>
  );
};

export default ContentCard;
