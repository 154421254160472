import React, { useState } from "react";
import TopFilters from "./Components/TopFilters/TopFilters";
import Filter from "./Components/section2/Filter";
import Sidebar from "./Components/Sidebar/Sidebar";
import CardList from "./Components/CardList/CardList";
import { FilterProvider } from "./Contexts/FilterContext";
import "./App.css";
import WhyHireSection from "./Components/WhyHireSection/WhyHireSection";
import Footer from "./Components/Footer/Footer";
import Modal from "./Components/ContentCard/Modal";

const App = () => {
  const [cardCount, setCardCount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDetailUrl, setSelectedDetailUrl] = useState("");
  const [queryParams, setQueryParams] = useState({}); // State to hold query parameters as an object

  const handleCardCountChange = (count) => {
    setCardCount(count);
  };

  const handleFiltersChange = (newQueryParams) => {
    setQueryParams(newQueryParams);
  };

  const handleOpenModal = (url) => {
    console.log("Opening modal with URL:", url);
    setSelectedDetailUrl(url);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedDetailUrl("");
  };

  return (
    <FilterProvider>
      <div className="app-container">
        <Filter />
        <div className="main-content">
          <Sidebar
            cardCount={cardCount} // Passing card count to Sidebar
            onFiltersChange={handleFiltersChange} // Passing filters change handler to Sidebar
          />
          <div className="content">
            <TopFilters />
            <CardList
              onCardCountChange={handleCardCountChange} // Passing card count handler to CardList
              onOpenModal={handleOpenModal}
              queryParams={queryParams} // Pass queryParams as an object to CardList
            />
          </div>
        </div>
        <WhyHireSection />
        <Footer />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        detailUrl={selectedDetailUrl}
      />
    </FilterProvider>
  );
};

export default App;
