import React, { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faDollarSign,
  faCheckCircle,
  faFileContract,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FilterContext } from "../../Contexts/FilterContext";
import img2 from "../../Assets/img2.png";
import "./Modal.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Modal = ({
  isOpen,
  onClose,
  detailData,
  activeTab,
  detailUrl,
  uniqueId,
  plan_uid,
}) => {
  const [currentTab, setCurrentTab] = useState(activeTab);
  const [pdfExists, setPdfExists] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [planData, setPlanData] = useState(null);

  const { filters } = useContext(FilterContext);

  const [formData, setFormData] = useState({
    name: "",
    rut: "",
    email: "",
    phone: "",
    isapre: "",
    plan_uid: uniqueId,
  });

  useEffect(() => {
    const fetchPlanData = async () => {
      const form = new FormData();
      form.append("plan_uid", uniqueId);

      try {
        const response = await fetch(
          "https://admin.zeenatonlinestore.com/api/plans",
          {
            method: "POST",
            body: form,
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          if (responseData.status === "success" && responseData.code === 200) {
            const planData = responseData.message[0];
            setPlanData(planData);
          } else {
            console.error("API response not successful:", responseData);
          }
        } else {
          console.error("API call failed:", response.statusText);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    if (isOpen) {
      fetchPlanData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && uniqueId) {
      const checkPdfExists = async () => {
        const fileUrl = `/pdf/${uniqueId}.pdf`;
        setPdfUrl(fileUrl);

        try {
          const response = await fetch(fileUrl, { method: "HEAD" });
          if (response.ok) {
            setPdfExists(true);
          } else {
            setPdfExists(false);
          }
        } catch (error) {
          setPdfExists(false);
          console.error("Error checking PDF existence:", error);
        }
      };

      checkPdfExists();
    }
  }, [isOpen, uniqueId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const proxyUrl = "https://your-proxy-url.com/";
    const webhookUrl =
      "https://connect.pabbly.com/workflow/sendwebhookdata/xxxxxxxxxxxxxxxxxxxx";

    try {
      const response = await fetch(proxyUrl + webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
      } else {
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const renderTableData = (tableData) => {
    if (!tableData) return <p>No table data available...</p>;

    let parsedTableData;
    try {
      parsedTableData = JSON.parse(tableData);
    } catch (error) {
      console.error("Error parsing table data:", error);
      return <p>Invalid table data format.</p>;
    }

    return (
      <table className="styled-table">
        <thead>
          <tr>
            <th>Clinic</th>
            <th>Hospitable</th>
            <th>Outpatient</th>
            <th>Urgency</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(parsedTableData).map(([clinic, values], index) => (
            <tr key={index}>
              <td>{clinic.replace(/\\/g, "")}</td>
              <td>{values[0] ? `${values[0]}%` : "-"}</td>
              <td>{values[1] ? `${values[1]}%` : "-"}</td>
              <td>{values[2] ? `${values[2]}%` : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderTable = () => {
    if (!planData)
      return (
        <p
          style={{
            textAlign: "center",
            marginTop: "20px",
            color: "red",
            fontWeight: "bold",
            fontSize: "20px",
          }}>
          Please wait data is loading...
        </p>
      );

    return <div>{renderTableData(planData.table_data)}</div>;
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case "general":
        return (
          <div className="tab-content">
            {renderTable()}
            {planData && (
              <div className="plan-coverage-section">
                <h3>Free Choice Coverage</h3>
                <p>For the rest of the providers, the coverage is:</p>
                <div className="coverage-details">
                  <div className="coverage-item">
                    <div className="icon">
                      <i className="fas fa-hospital-alt"></i>
                    </div>
                    <div className="coverage-info">
                      <h4>Hospital Coverage</h4>
                      <p>(Operations, illnesses, etc.)</p>
                    </div>
                    <div className="coverage-percentage">
                      {planData.hospitalaria_percentage || "N/A"}
                    </div>
                  </div>
                  <div className="coverage-item">
                    <div className="icon">
                      <i className="fas fa-user-md"></i>
                    </div>
                    <div className="coverage-info">
                      <h4>Outpatient Coverage</h4>
                      <p>(Medical consultations, exams, etc.)</p>
                    </div>
                    <div className="coverage-percentage">
                      {planData.ambulatoria_percentage || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="less-coverage">
                  <p>You will have less coverage in:</p>
                  <ul>
                    <li>Bariatric surgery</li>
                    <li>Refractive surgery</li>
                    <li>Other restricted benefits</li>
                  </ul>
                </div>
                <div className="additional-info">
                  <div className="info-item">
                    <p>
                      This plan covers up to <strong>7,000 UF</strong>{" "}
                      ($263,984,140) per year per person. If this amount is
                      exceeded, the legal minimum is credited.
                    </p>
                  </div>
                  <div className="info-item">
                    <p>This plan includes childbirth coverage.</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );

      case "price":
        return (
          <div className="price-content">
            <h4>Price per Person</h4>
            <div className="price-chart">
              <Bar
                data={{
                  labels: ["GES", "Valor Plan"],
                  datasets: [
                    {
                      label: "Price",
                      data: [0.6, 5.93],
                      backgroundColor: ["#4CAF50", "#2196F3"],
                      borderColor: ["#388E3C", "#1976D2"],
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </div>
            <p>
              <strong>Consalud GES:</strong> 0.60 UF
            </p>
          </div>
        );

      case "request":
        return (
          <div className="form-section">
            <h3>Request</h3>
            <p>Hire and/or receive advice from a specialized executive</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="rut">RUT*</label>
                <input
                  type="text"
                  id="rut"
                  name="rut"
                  value={formData.rut}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone*</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Is Consalud your current Isapre?</label>
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="isapre"
                      value="no"
                      checked={formData.isapre === "no"}
                      onChange={handleInputChange}
                    />{" "}
                    No
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="isapre"
                      value="yes"
                      checked={formData.isapre === "yes"}
                      onChange={handleInputChange}
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>
              <button className="submit-button" type="submit">
                Request with executive
              </button>
            </form>
          </div>
        );

      case "contract":
        return (
          <div className="contract-section">
            <h3>Contract</h3>
            <p>Download your contract details or fill in the form below.</p>
            {pdfExists ? (
              <a href={pdfUrl} download className="download-button">
                <FontAwesomeIcon icon={faFileDownload} className="fa-icon" />{" "}
                Download PDF
              </a>
            ) : (
              <p>No contract available for download.</p>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone*</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button className="submit-button" type="submit">
                Submit
              </button>
            </form>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
      ariaHideApp={false}>
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
      <div className="modal-header">
        {planData && planData.logo ? (
          <img
            src={`https://admin.zeenatonlinestore.com/${planData.logo}`}
            alt="Consalud Logo"
            className="logo"
          />
        ) : (
          <p>Loading logo...</p>
        )}
        <div className="modal-title-price">
          <h2>SELECT FULL 1202 24</h2>
          <div className="modal-price">
            <span>From</span>
            {filters.currency === "usd" && filters.calculatedPrice ? (
              <h2>${filters.calculatedPrice} UF</h2>
            ) : filters.calculatedPrice ? (
              <h2>{filters.calculatedPrice} CLP</h2>
            ) : (
              <h2>Price Not Available</h2>
            )}
            <button className="request-button">
              REQUEST A PLAN FROM CONSALUD
            </button>
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="modal-tabs">
          <button
            className={currentTab === "general" ? "active" : ""}
            onClick={() => setCurrentTab("general")}>
            <FontAwesomeIcon icon={faEye} className="fa-icon" /> General view
          </button>

          <button
            className={currentTab === "price" ? "active" : ""}
            onClick={() => setCurrentTab("price")}>
            <FontAwesomeIcon icon={faDollarSign} className="fa-icon" /> Price
            View
          </button>

          <button
            className={currentTab === "request" ? "active" : ""}
            onClick={() => setCurrentTab("request")}>
            <FontAwesomeIcon icon={faCheckCircle} className="fa-icon" /> Request
          </button>

          <button
            className={currentTab === "contract" ? "active" : ""}
            onClick={() => setCurrentTab("contract")}>
            <FontAwesomeIcon icon={faFileContract} className="fa-icon" />{" "}
            Contract
          </button>
        </div>
        <div className="tab-content">{renderTabContent()}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
