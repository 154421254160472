import React, { createContext, useState } from "react";

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const initialFilters = {
    region: "",
    income: "",
    age: "",
    sex: "",
    load: [],
    someData: [],
    clinicFilters: {
      freeChoice: true,
      preferred: true,
      closed: true,
    },
    isapresFilters: [
      "Banmédica",
      "ColmenaGolden",
      "Consalud",
      "CruzBlanca",
      "Esencial",
      "Nueva Masvida",
      "Vidatres",
    ],
    priceRange: [0, 500],
    sortBy: "best_score",
    currency: "pesos",
  };

  const getFiltersFromSession = () => {
    const storedFilters = sessionStorage.getItem("filters");
    if (storedFilters) {
      try {
        const parsedFilters = JSON.parse(storedFilters);
        console.log("Parsed Filters from Session:", parsedFilters); // Debugging

        return {
          ...initialFilters,
          ...parsedFilters,
          load: Array.isArray(parsedFilters.load) ? parsedFilters.load : [], // Ensure load is an array
          isapresFilters: Array.isArray(parsedFilters.isapresFilters)
            ? parsedFilters.isapresFilters
            : initialFilters.isapresFilters, // Ensure isapresFilters is an array
        };
      } catch (error) {
        console.error("Error parsing filters from sessionStorage", error);
        return initialFilters;
      }
    }
    return initialFilters;
  };

  const [filters, setFilters] = useState(getFiltersFromSession);

  const setAndUpdateFilters = (newFilters) => {
    setFilters(newFilters);

    sessionStorage.setItem("filters", JSON.stringify(newFilters));
  };

  return (
    <FilterContext.Provider
      value={{ filters, setFilters: setAndUpdateFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
