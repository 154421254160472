import React, { useState, useEffect, useContext } from "react";
import ContentCard from "../ContentCard/ContentCard";
import "./CardList.css";
import { FilterContext } from "../../Contexts/FilterContext";

const CardList = ({ queryParams, onCardCountChange }) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 10; // Number of cards per page
  const { filters } = useContext(FilterContext);

  useEffect(() => {
    const fetchCards = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://admin.zeenatonlinestore.com/api/plans?${new URLSearchParams(
            queryParams
          )}`
        );
        const data = await response.json();

        console.log("API Response:", data);

        if (Array.isArray(data)) {
          setCards(data);
        } else {
          console.error("Unexpected API response format:", data);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, [queryParams]);

  const UF_TO_CLP_RATE = 37664.36;

  const isWithinPriceRange = (card) => {
    const { priceRange, currency } = filters;

    if (!priceRange || (priceRange[0] === 0 && priceRange[1] === 500)) {
      return true;  
    }

    const [minPrice, maxPrice] = priceRange;
    const storedPrice = parseFloat(card.price);

    return storedPrice >= minPrice && storedPrice <= maxPrice;
  };
 
  let filteredCards = cards.filter(isWithinPriceRange);
 
  filteredCards.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
 
  useEffect(() => {
    if (onCardCountChange) {
      onCardCountChange(filteredCards.length);
    }
  }, [filteredCards, onCardCountChange]);
 
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredCards.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);  
  };

  const goToNextPage = () => {
    if (currentPage < Math.ceil(filteredCards.length / cardsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    );
  }

  return (
    <div className="card-list">
      {currentCards.length > 0 ? (
        currentCards.map((card) => (
          <ContentCard
            key={card.id}
            card={card}
            selectedCurrency={filters.currency}
            ufToClpRate={UF_TO_CLP_RATE}
          />
        ))
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "25px",
            fontWeight: "bold",
            color: "red",
          }}>
          "No Data Available for this This Price!"
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(filteredCards.length / cardsPerPage)}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
      />
    </div>
  );
};

const Pagination = ({
  currentPage,
  totalPages,
  goToNextPage,
  goToPreviousPage,
}) => {
  return (
    <div className="pagination">
      <button
        className="pagination-button"
        onClick={goToPreviousPage}
        disabled={currentPage === 1}>
        Previous
      </button>
      <span className="pagination-info">
        Page {currentPage} of {totalPages}
      </span>
      <button
        className="pagination-button"
        onClick={goToNextPage}
        disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};

export default CardList;
